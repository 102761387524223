<template>
  <div class="edit">
    <!-- 修改资料 -->
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      size="small"
      label-position="right"
      :label-width="'100px'"
    >
      <el-form-item label="账号：" prop="name">
        <el-input v-model="form.name" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="mobile">
        <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：" prop="email">
        <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="原密码：" prop="original">
        <el-input
          v-model="form.original"
          placeholder="请输入原密码"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="password">
        <el-input
          v-model="form.password"
          placeholder="请输入新密码"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="con_password">
        <el-input
          v-model="form.con_password"
          placeholder="再次输入新密码"
          type="password"
        ></el-input>
      </el-form-item>
      <div style="display: flex; justify-content: center; align-items: center">
        <el-button
          type="primary"
          size="small"
          class="btn_w"
          @click="handleEditSave('form')"
          >保存</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEdit: false,
      form: {
        name: JSON.parse(localStorage.getItem("userInfo")).name,
        mobile: JSON.parse(localStorage.getItem("userInfo")).mobile,
        email: JSON.parse(localStorage.getItem("userInfo")).email,
        original: "",
        password: "",
        con_password: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "change",
          },
        ],
        mobile: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "change",
          },
        ],
        original: [
          {
            required: true,
            message: "原密码不能为空",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "新密码不能为空",
            trigger: "change",
          },
        ],
        con_password: [
          {
            required: true,
            message: "确认密码不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    handleEditSave(form) {
      if (this.form.password !== this.form.con_password) {
        this.$message({
          message: "两次密码不一致!",
          type: "error",
        });
      } else {
        this.$refs[form].validate((valid) => {
          if (valid) {
            this.$axios
              .post("admin/user/edit", {
                name: this.form.name,
                email: this.form.email,
                mobile: this.form.mobile,
                pwd: this.form.password,
                confirm: this.form.con_password,
                original: this.form.original,
              })
              .then((res) => {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.$axios.get("admin/user/logout").then(() => {
                  localStorage.removeItem("userInfo");
                  this.$router.push("/login");
                });
              });
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.edit {
  margin: 15px;
  width: 30%;
}
.btn_w {
  width: 90px;
}
</style>